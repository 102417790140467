<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <img class="logo" :src="logoImage" height="50" alt="" />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center" style="padding: 5px 0;
          border-radius: 5px;
          background-color: white;">
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
        <h2 class="mb-1 mt-1">
          {{$t('auth.maintenance')}}
        </h2>
        <p class="mb-2">
          {{$t('auth.maintenance_description')}}
        </p>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import { logoImageFunction } from '@/main';

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/logo/sad_seal.jpg'),
      //downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  mounted() {
    this.obtenerIdiomaNavegador();
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return this.downImg
      }
      return this.downImg
    },
    logoImage() {
      logoImageFunction();
    },
  },
  methods: {
    obtenerIdiomaNavegador() {
      let idiomaNavegador = navigator.language || navigator.userLanguage;
      idiomaNavegador = idiomaNavegador.split("-")[0];
      if (Object.keys(this.$i18n.messages).includes(idiomaNavegador)) {
        this.$i18n.locale = idiomaNavegador;
      } else {
        this.$i18n.locale = 'en';
      }
      this.$forceUpdate();
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
